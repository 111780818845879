import { AppConfig } from "src/app/config/app.config";

export class SharedSettings {
  // --------------individual service urls --------------------------//

  public static get accountServiceUrl(): any {
    return `${AppConfig.settings.accountServiceUrl}`;
  }

  public static get subscriptionServiceUrl(): any {
    return `${AppConfig.settings.subscriptionServiceUrl}`;
  }

  public static get userInvitationLimit(): number {
    return AppConfig.settings.userInvitationLimit;
  }

  public static get editProfileUrl(): any {
    return `${AppConfig.settings.editProfileUrl}`;
  }
  public static get domain(): any {
    return `${AppConfig.settings.domain}`;
  }
  public static get clientId(): any {
    return `${AppConfig.settings.clientId}`;
  }
  public static get audience(): any {
    return `${AppConfig.settings.audience}`;
  }
  public static get claimName(): any {
    return `${AppConfig.settings.claimName}`;
  }
  public static get launchDarklyClientID(): any {
    return `${AppConfig.settings.launchDarklyClientID}`;
  }
}
