import { Inject } from "@angular/core";
import { Injectable } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class DOMEvents {
  private doc: Document;

  constructor(@Inject(DOCUMENT) doc: any) {
    this.doc = doc;
  }

  // Trigger the given event on the document root.
  public triggerOnDocument(eventType: string): Event {
    return this.triggerOnElement(this.doc, eventType);
  }

  // Trigger the given event configuration on the given element.
  public triggerOnElement(
    nativeElement: any,
    eventType: string,
    bubbles: boolean = true,
    cancelable: boolean = false
  ): Event {
    let customEvent = this.createEvent(eventType, bubbles, cancelable);

    nativeElement.dispatchEvent(customEvent);

    return customEvent;
  }

  // Create and return a custom event with the given configuration.
  private createEvent(
    eventType: string,
    bubbles: boolean,
    cancelable: boolean
  ): Event {
    let customEvent: any;

    try {
      customEvent = new CustomEvent(eventType, {
        bubbles: bubbles,
        cancelable: cancelable,
      });
    } catch (error) {
      customEvent = this.doc.createEvent("CustomEvent");

      customEvent.initCustomEvent(eventType, bubbles, cancelable);
    }

    return customEvent;
  }
}
