export const locale = {
  lang: "en",
  localeData: {
    TITLE: "Diva Space",
    fullName: "Full Name",
    displayName: "Display Name",
    save: "Save",
    email: "E-mail address",
    emailPlaceholder: "Add e-mail address",
    send: "Send",
    logout: "Logout",
    role: "Role",
    blocked: "Blocked",
    login: {
      capital: "LOGIN",
      lower: "login",
      pascal: "Login",
      spaceErrorMessage:
        "You are not associated with any space, Please contact your system administrator",
      signInHeaderMessage: "Sign in failed!",
      spaceNotFound:
        "Failed to get space information, Please contact your system administrator",
      loginError: "Invalid Email or Password.",
      userNotFound: "User not found. Please login again.",
      error: {
        dismiss: "Dismiss",
        rateLimit: {
          informationMessage: "Too many users log in at the same time.",
          instructionMessage: "Please try again in a few minutes.",
        },
        unregistered: {
          informationMessage: "The e-mail address is not yet registered.",
          instructionMessage:
            "Please ask your space owner to invite you to Space.",
        },
        initialization: {
          informationMessage: "Failed to link your account to DIVA Account.",
          instructionMessage:
            "Please try again or report this issue to your IT support.",
        },
        internal: {
          informationMessage: "Failed to log in due to an error.",
          instructionMessage:
            "Please try again or report this issue to your IT support.",
        },
        fallback: {
          informationMessage: "Something went wrong.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        getUserFailed: {
          informationMessage: "Failed to get user information.",
          instructionMessage: "Please try again or report this issue to your IT support."
        },
        invalidDomain: {
          informationMessage:
            "The e-mail address does not use external authentication.",
          instructionMessage:
            "Please try again with an e-mail that can use external authentication.",
        },
        sessionExpired: {
          informationMessage: "Your session has expired.",
          instructionMessage: "Please log in again.",
        },
        blocked: {
          informationMessage: "Your account has been blocked.",
          instructionMessage: "To unblock your account, please contact your IT support."
        }
      },
    },
    signUp: {
      title: "Space Owner Sign Up",
      signUpButton: "Sign Up",
      firstName: "First Name",
      lastName: "Last Name",
      displayName: "Display Name",
      email: "Email",
      password: "Password",
      confirmPassword: "Confirm Password",
      confirmPasswordMismatchError: "Password and Confirm Password must match.",
      signUpDone: "Sign up done successfully",
      invalidRequest: "Invalid request for sign up.",
      invalidRequestData: "Invalid sign up request data.",
      signupAlreadyDone: "Sign up done already for this request.",
      signupError: "Some error occured while signup.",
    },
    spaceSetup: {
      title: "Space Setup",
      currentSpaceError: "Error in getting space detail",
      success: "Space setup successfully",
      failed: "Failed to setup space",
      name: "Space Name",
      description: "Description",
      save: "Save",
      emptyNameError: "Please enter space name.",
    },
    userlist: "User List",
    spaceSetting: "Space Settings",
    subscriptionList: "Subscription",
    spaceSettingUpdate: {
      title: "Space Settings",
      currentSpaceError: "Error in getting space detail",
      success: "Space detail updated successfully",
      failed: "Failed to update space detail",
      name: "Space Name",
      description: "Description",
      save: "Save",
      cancel: "Cancel",
      id: "Space ID",
      copySpaceId: "Space ID Copied",
      information: "Space Information",
      emptyNameError: "Please insert space name.",
      allowedDomains: "Allowed Domains",
      allowedDomainsPlaceholder: "diva.co.jp, example.com",
      allowedDomainsDescription:
        "Space owner can invite these domains when the checkbox is checked.",
      invalidDomains:
        "Invalid input. Separate with a comma (,) if using multiple domains.",
      restrictUser: "Restrict the user while inviting to the space.",
      restrictUserDescription:
        "Space owner can invite only the allowed domains and connection domains.",
    },
    spaceSettingConnection: {
      title: "Integration with External Authentication Option",
      description:
        "You will find the connection name and domains, that shows us all the registered enterprise connections to this space.\nDirectory shows Microsoft Azure AD domain (if using Azure AD integration) or primary email domain (if using SAML integration).",
      emptyConnection:
        "There are no registered integration. Please ask the service to set the integration with external authentication in the Space.",
      connectionName: "Connection Name",
      tenantDomain: "Directory",
      emailDomains: "Email domains",
      owners: "Owners",
      enabled: "Status",
      enabledStatusTrue: "Enabled",
      enabledStatusFalse: "Disabled",
      error: "Error getting IdP connection details",
    },
    editProfile: {
      fullNamePlaceholder: "Enter full name",
      displayNamePlaceholder: "Enter display name",
      emailPlaceholder: "user@email.com",
    },
    header: {
      editProfile: "Edit Profile",
      languageSetting: "Language Settings",
    },
    userList: {
      title: "User List",
      btnInviteUser: "Invite User",
      searchUsers: "Search for Users",
      gridHeaderName: "Name",
      gridHeaderEmail: "Email",
      gridHeaderRole: "Role",
      changeRoleSuccessMessage: "Role updated successfully",
      changeRoleFailedMessage: "Failed to update role",
      removeUserConfirmMessage: "Remove user [UserName] from space?",
      removeOwnConfirmMessage:
        "You will not be able to access this space after remove your self.\n \nDo you want to remove your self from space?",
      removeUserConfirmTitle: "Remove User?",
      removeUserSuccessMessage: "User removed from space successfully",
      removeUserFailedMessage: "Failed to remove user from space",
      removeUserButton: "Remove User",
      ownRoleChangeConfirmTitle: "Change Role?",
      ownRoleChangeConfirmMessage:
        "You will not be able to access this space after change your role.\n \nDo you want to change your role?",
      lastLogin: "Last Login",
      neverLogin: "Never Login",
      resendButton: "Resend Invitation",
      resendSuccess:
        "Invitation has been resent. \n You can resend again after 3 minutes.",
      resendAlreadySignedUp: "User is already signed up.",
      resendBadRequest: "Bad Request",
      resendUserNotFound: "User not found",
      resendUserNotFoundInSpace: "User not found in space",
      resendNotFound: "Resource not found",
      resendFailed: "Failed to resend invitation",
      apiError: "Failed to get users"
    },
    mfa: {
      acronym: "MFA",
      statusLabel: "Multi-Factor Authentication (MFA) status",
      disabled: "Disabled",
      enabled: "Enabled",
    },
    inviteMember: {
      title: "Invite User",
      roleDropdown: "Role",
      addMore: "Invite more",
      successMessage: "Invitation sent successfully.",
      failedMessage: "Some invitation failed or already invited.",
      failedInvite: "Some error occured while sending invitation",
      invalidDomain:
        "Can not invite because the user's email address does not contain an invitable domains",
      sendMailCheck: "Do not notify the user(s) via e-mail",
      error: {
        empty: "Required",
        domain: "Domain is not allowed",
        email: "Invalid email address",
      },
    },
    dashboard: {
      confirmMessage: "Do you want to switch space?",
      confirmDialogTitle: "Switch Space?",
    },
    "no-permission": {
      accessMessage:
        "You don't have permission to access <b>[spaceName]</b> because you are not the Space Owner of this space.",
      spaceSelectMessage: "Please select other space from space selector.",
      noPermissionMessage: "You don't have permission to access this space.",
      tryAgainMessage: "If you have recently obtained the permission, please close the browser and try again.",
    },
    subscription: {
      gridHeaderProduct: "Product Information",
      gridHeaderSubscriptionNumber: "Subscription Number",
      gridHeaderSubscriptionInfo: "Subscription Information",
      gridHeaderUsageLimit: "Usage Limit",
      emptyList: "There is no subscription",
      apiError: "Failed to get subscriptions"
    },
  },
};
