import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { languages } from "./languages";
import { locale as translateEnglish } from "../../../assets/i18n/en";
import { locale as translateJapanese } from "../../../assets/i18n/ja";

export interface Locale {
  lang: string;
  localeData: Object;
}

@Injectable({
  providedIn: "root",
})
export class LanguageConfig {
  constructor(private translateService: TranslateService) {}

  private readonly languageIds = languages.map((language) => language.id);
  private readonly defaultLanguageId = "en";

  load() {
    return new Promise<void>((resolve, reject) => {
      const localStoreLang: string = localStorage.getItem("localStoreLang");
      const browserLang: string = this.translateService.getBrowserLang();

      // add languages
      this.translateService.addLangs(this.languageIds);

      // set default language for app
      this.translateService.setDefaultLang(
        localStoreLang && this.isAvailableLanguage(localStoreLang)
          ? localStoreLang
          : browserLang && this.isAvailableLanguage(browserLang)
            ? browserLang
            : this.defaultLanguageId
      );

      // Setting the default language
      localStorage.setItem("localStoreLang", this.translateService.getDefaultLang());

      this.loadTranslations(translateEnglish, translateJapanese);

      resolve();
    });
  }

  /**
   * Check whether if a given languageId is one of available languages
   *
   * @param languageId
   */
  private isAvailableLanguage(languageId: string): boolean {
    if(languageId.match(this.languageIds.join("|"))) {
      return true;
    }

    return false;
  }

  /**
   * Load translations
   *
   * @param {Locale} args
   */
  loadTranslations(...args: Locale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translateService.setTranslation(locale.lang, locale.localeData, true);
    });
  }
}
