import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  HttpClientModule,
} from "@angular/common/http";
import { AppConfig } from "./config/app.config";
import { HttpRequestInterceptorService } from "./interceptor/http-request-interceptor.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { LanguageConfig } from "./config/language/language.config";
import { AuthConfigService, AuthGuard, AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular";
import { CommonModule } from "@angular/common";
import {
  OverlayContainer,
  FullscreenOverlayContainer,
} from "@angular/cdk/overlay";
import { GlobalService } from "./shared/services/global.service";
import { DOMEvents } from "./shared/services/dom-events.service";
import { AlertService } from "diva-lib";
import { LoginComponent } from "./core/auth/login/login.component";
import { LoginCallbackComponent } from "./core/auth/logincallback/login.callback.component";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { SharedSettings } from "./shared/setting/shared-settings";

export let initializeApp = (appConfig: AppConfig) => {
  return () => appConfig.load();
};

export let initializeLanguage = (languageConfig: LanguageConfig) => {
  return () => languageConfig.load();
};

export let initializeSpace = () => {
  // set userSpaceID value in localStorage (last selected space ID) to sessionStorage
  return () => {
    return new Promise<void>((resolve) => {
      if (!sessionStorage.getItem("userSpaceID")) {
        sessionStorage.setItem("userSpaceID", localStorage.getItem("userSpaceID"));
      }
      resolve();
    });
  };
};

@NgModule({
  declarations: [AppComponent, LoginComponent, LoginCallbackComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    MatButtonModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    MatSnackBarModule,
    MatDialogModule,
    MatSortModule,
    MatTableModule,
    AuthModule.forRoot(),
  ],
  exports: [],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig, HttpClient],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeLanguage,
      deps: [LanguageConfig, TranslateService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSpace,
      deps: [],
      multi: true,
    },
    GlobalService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptorService,
      multi: true,
    },
    {
      provide: AuthConfigService,
      useFactory: () => ({
        domain: SharedSettings.domain,
        clientId: SharedSettings.clientId,
        authorizationParams: {
          /* eslint-disable camelcase */
          redirect_uri: `${window.location.origin }/logincallback`,
          ui_locales: localStorage.getItem("localStoreLang"),
          /* eslint-enable camelcase */
          audience: SharedSettings.audience,
          httpInterceptor: {
            allowedList: [`${SharedSettings.audience }*`],
          },
        },
      }),
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    DOMEvents,
    AlertService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
