import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";
import { AuthService } from "@auth0/auth0-angular";
import { ErrorCodes } from "../core/auth/errorcodes";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class HttpRequestInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has("NoAuthValidate")) {
      return this.authService.getAccessTokenSilently().pipe(
        mergeMap((token) => {
          let space = sessionStorage.getItem("userSpaceID");

          if (!space) {
            space = "";
          }
          const tokenReq = req.clone({
            setHeaders: { Authorization: `Bearer ${token}`, Space: space },
          });

          return next.handle(tokenReq);
        }),
        catchError((error) => {
          const noPermissionStatusCode = 403;
          const unauthorizedStatusCode = 401;

          if (error && error.status === noPermissionStatusCode) {
            if (window.location.pathname !== "/permission/no-access") {
              this.router.navigate(["permission/no-access"]);
            }
          }

          if (error.status === unauthorizedStatusCode) {
            this.authService.logout({
              logoutParams: {
                returnTo:
                `${document.location.origin
                }/index?errorcode=${
                  ErrorCodes.SESSIONEXPIRED}`,
              }
            });
          }

          return throwError(() => error);
        })
      );
    }
    return next.handle(req);
  }
}
