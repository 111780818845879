export const locale = {
  lang: "ja",
  localeData: {
    TITLE: "Diva Space Japanese",
    fullName: "氏名",
    displayName: "ディスプレイ名",
    save: "保存する",
    email: "メールアドレス",
    emailPlaceholder: "メールアドレスを入力する",
    send: "送信",
    logout: "ログアウト",
    role: "ロール",
    blocked: "ブロックされました",
    login: {
      capital: "ログイン",
      lower: "ログイン",
      pascal: "ログイン",
      spaceErrorMessage:
        "利用できるSpaceがありません。管理者にお問い合わせください。",
      signInHeaderMessage: "ログインに失敗しました。",
      spaceNotFound:
        "Space情報が取得できませんでした。管理者にお問い合わせください。",
      loginError: "ユーザー名またはパスワードが間違っています。",
      userNotFound:
        "ユーザーが見つかりませんでした。もう一度ログインしてください。",
      error: {
        dismiss: "閉じる",
        rateLimit: {
          informationMessage: "多くのユーザーが同時にログインしました。",
          instructionMessage: "数分後にもう一度お試しください。",
        },
        unregistered: {
          informationMessage: "メールアドレスが未登録です。",
          instructionMessage:
            "スペースオーナーにスペースへの招待を依頼してください。",
        },
        initialization: {
          informationMessage:
            "お客様のアカウントでDIVAアカウントへのリンクを失敗しました。",
          instructionMessage:
            "もう一度お試しいただくか、この問題をITサポートに報告してください。",
        },
        internal: {
          informationMessage: "エラーによりログインに失敗しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。"
        },
        fallback: {
          informationMessage: "エラーが発生しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。"
        },
        getUserFailed: {
          informationMessage: "ユーザー情報の取得に失敗しました。",
          instructionMessage: "もう一度お試しいただくか、この問題をITサポートに報告してください。"
        },
        invalidDomain: {
          informationMessage: "このメールアドレスは外部認証を利用できません。",
          instructionMessage:
            "外部認証を利用できるメールアドレスで再度お試しください。",
        },
        sessionExpired: {
          informationMessage: "セッションの有効期限が切れました。",
          instructionMessage: "再度ログインしてください。",
        },
        blocked: {
          informationMessage: "このアカウントはブロックされています。",
          instructionMessage: "アカウントのブロックを解除するには、ITサポートにお問い合わせください。"
        }
      },
    },
    signUp: {
      title: "Space Owner登録",
      signUpButton: "サインアップ",
      firstName: "名",
      lastName: "姓",
      displayName: "ディスプレイ名",
      email: "メール",
      password: "パスワード",
      confirmPassword: "確認",
      confirmPasswordMismatchError:
        "パスワードとパスワードの確認は一致することが必要です",
      signUpDone: "登録できました",
      invalidRequest: "登録リクエストは無効です",
      invalidRequestData: "登録キーまたはメールリクエストは無効です",
      signupAlreadyDone: "すでに登録していました",
      signupError: "登録にエラーが発生しました",
    },
    spaceSetup: {
      title: "Spaceセットアップ",
      currentSpaceError: "Space詳細情報取得にエラーが発生しました",
      success: "Spaceのセットアップができました",
      failed: "Spaceのセットアップができませんでした",
      name: "Space名",
      description: "説明",
      emptyNameError: "Space名を入力してください。",
    },
    userlist: "ユーザーリスト",
    spaceSetting: "Space設定",
    subscriptionList: "サブスクリプション",
    spaceSettingUpdate: {
      title: "Space設定",
      currentSpaceError: "Space詳細情報取得にエラーが発生しました",
      success: "Space詳細が更新できました",
      failed: "Space詳細が更新できませんでした",
      name: "Space名",
      description: "説明",
      cancel: "キャンセル",
      id: "SpaceID",
      copySpaceId: "SpaceIDをコピーしました",
      information: "Space情報",
      emptyNameError: "Space名を入力してください。",
      allowedDomains: "許可するドメイン",
      allowedDomainsPlaceholder: "diva.co.jp, example.com",
      allowedDomainsDescription:
        "ユーザー招待を許可するドメインを入力してください。複数指定する場合はカンマ（,）で区切ってください。",
      invalidDomains:
        "ドメインが不正です。複数ドメインを設定する場合はカンマ(,)で区切ってください。",
      restrictUser: "ユーザーの招待でドメインの制限を有効にする",
      restrictUserDescription:
        "招待するユーザーを指定のドメインのみに制限することができます。",
    },
    spaceSettingConnection: {
      title: "外部認証連携オプション",
      description: "外部IDプロバイダとの接続とそのドメインの一覧です。\nディレクトリはMicrosoft Azure ADドメイン（Azure AD連携）または優先メールドメイン（SAML連携）が表示されます。",
      emptyConnection:
        "外部認証連携はありません。外部のIDプロバイダと連携してユーザ認証を行う場合はサービスまでお問い合わせください。",
      connectionName: "接続名",
      tenantDomain: "ディレクトリ",
      emailDomains: "メールドメイン",
      owners: "管理者",
      enabled: "ステータス",
      enabledStatusTrue: "有効",
      enabledStatusFalse: "無効",
      error: "Enterprise Connectionsの取得に失敗しました。",
    },
    editProfile: {
      fullNamePlaceholder: "氏名を入力してください",
      displayNamePlaceholder: "表示名を入力してください",
      emailPlaceholder: "example@example.com",
    },
    header: {
      editProfile: "プロフィール編集",
      languageSetting: "言語設定",
    },
    userList: {
      title: "ユーザーリスト",
      btnInviteUser: "ユーザー招待",
      searchUsers: "ユーザー検索",
      gridHeaderName: "氏名",
      gridHeaderEmail: "メール",
      gridHeaderRole: "ロール",
      changeRoleSuccessMessage: "ロールが更新できました",
      changeRoleFailedMessage: "ロールが更新できませんでした",
      removeUserConfirmMessage: "Spaceからユーザー [UserName] を削除しますか？",
      removeOwnConfirmMessage:
        "退去した場合は、Spaceにアクセスすることができなくなります.\n \n 実行しますか？",
      removeUserConfirmTitle: "ユーザーを削除しますか？",
      removeUserSuccessMessage: "ユーザーを削除できました",
      removeUserFailedMessage: "ユーザーを削除できませんでした",
      removeUserButton: "ユーザー削除",
      ownRoleChangeConfirmTitle: "ロールを変更しますか？",
      ownRoleChangeConfirmMessage:
        "ロールを変更した場合は、Spaceにアクセスすることができなくなります.\n \n実行しますか？",
      lastLogin: "最終ログイン",
      neverLogin: "Never Login",
      resendButton: "招待を再送信",
      resendSuccess: "招待が再送信できました",
      resendAlreadySignedUp: "ユーザーはすでに登録されています",
      resendBadRequest: "無効なリクエスト",
      resendUserNotFound: "ユーザーが見つかりません",
      resendUserNotFoundInSpace: "Spaceにユーザーが見つかりません",
      resendNotFound: "リソースが見つかりません",
      resendFailed: "招待が再送信できませんでした",
      apiError: "ユーザーの取得に失敗しました"
    },
    mfa: {
      acronym: "MFA",
      statusLabel: "多要素認証(MFA)の状態",
      disabled: "無効",
      enabled: "有効",
    },
    inviteMember: {
      title: "ユーザー招待",
      roleDropdown: "ロール",
      addMore: "さらに招待",
      successMessage: "招待しました",
      failedMessage: "送信ができません、またはすでに招待されています",
      failedInvite: "ユーザー招待中にエラーが発生しました",
      invalidDomain:
        "ユーザーのメールアドレスに利用できないドメインが含まれているため招待できません",
      sendMailCheck: "招待メールを送信しない",
      error: {
        empty: "必須項目です",
        domain: "このドメインは許可されていません",
        email: "メールアドレスの書式が不正です",
      },
    },
    dashboard: {
      confirmMessage: "Spaceを切り替えますか？",
      confirmDialogTitle: "Space切り替え",
    },
    "no-permission": {
      accessMessage:
        "Space Ownerではないため、Space <b>[spaceName]</b> にアクセスできません。",
      spaceSelectMessage: "Spaceセレクターから、Spaceを選択してください。",
      noPermissionMessage: "Spaceにアクセスできません。",
      tryAgainMessage: "アクセス権を付与されたばかりの場合は、ブラウザを閉じてアクセスしなおしてください。"
    },
    subscription: {
      gridHeaderProduct: "商品情報",
      gridHeaderSubscriptionNumber: "サブスクリプション番号",
      gridHeaderSubscriptionInfo: "サブスクリプション情報",
      gridHeaderUsageLimit: "使用制限",
      emptyList: "サブスクリプションがありません",
      apiError: "サブスクリプションの取得に失敗しました"
    },
  },
};
