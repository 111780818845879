import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
import { SharedSettings } from "../setting/shared-settings";
import { map, catchError, concatMap } from "rxjs/operators";
import { UserModel } from "../models/user.model";
import { Router } from "@angular/router";
import { Roles } from "../enums/enums";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  private privateLoggedInUser: UserModel;
  onSpaceChanged = new Subject<any>();
  private isFirstOwnerCall = true;
  private isSpaceOwner = null;
  constructor(private httpClient: HttpClient, private router: Router) {}

  public get CurrentSpace() {
    return sessionStorage.getItem("userSpaceID");
  }

  public set CurrentSpace(spaceId: string) {
    sessionStorage.setItem("userSpaceID", spaceId);
    localStorage.setItem("userSpaceID", spaceId);
  }

  public get loggedInUser(): UserModel {
    return this.privateLoggedInUser;
  }

  public set loggedInUser(user: UserModel) {
    this.privateLoggedInUser = user;
    localStorage.setItem("userID", user.id);
  }

  public getIsSpaceOwner() {
    return this.isSpaceOwner;
  }

  public setIsSpaceOwner(value: boolean | null) {
    this.isSpaceOwner = value;
  }

  getUser(): Observable<any> {
    return this.httpClient
      .get(`${SharedSettings.accountServiceUrl }management/users/me`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  getUserSpaces(): Observable<any> {
    return this.httpClient
      .get(`${SharedSettings.accountServiceUrl }spaces`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  getRoles(): Observable<any> {
    return this.httpClient
      .get(`${SharedSettings.accountServiceUrl }spaces/current/users/me/role`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error) => {
          throw error;
        })
      );
  }

  isOwner(): Observable<boolean> {
    // If the isSpaceOwner flag is set, no need to check from backend
    if (this.isSpaceOwner === null) {
      // If current space id already set then directly check user role
      // Added "isFirstOwnerCall" condition to check current space is in available spaces in first call.
      if (this.CurrentSpace && !this.isFirstOwnerCall) {
        return this.getRoles().pipe(
          map((roles: string[]) => {
            let result = roles.some((role) => role === Roles.Owner);

            this.isSpaceOwner = result;
            return result;
          }),
          catchError((error) => {
            throw error;
          })
        );
      }
      // Here space id does not found. So firstly we get spaces and set default space then get user role.
      return this.getUserSpaces().pipe(
        concatMap((spaces) => {
          let minimumLength = 0;
          let initialIndex = 0;

          if (spaces && spaces !== null && spaces.length > minimumLength) {
            // Check current space is in available spaces. If not then select first space as current space.
            if (!spaces.some((space) => space.id === this.CurrentSpace)) {
              this.CurrentSpace = spaces[initialIndex].id;
            }

            this.isFirstOwnerCall = false;
            return this.getRoles().pipe(
              map((roles: string[]) => {
                let result = roles.some((role) => role === Roles.Owner);

                this.isSpaceOwner = result;
                return result;
              }),
              catchError((error) => {
                throw error;
              })
            );
          }

          return of(false);
        }),
        catchError((error) => {
          throw error;
        })
      );
    }
    return of(this.isSpaceOwner);
  }

  changeSpace(spaceId: string) {
    this.CurrentSpace = spaceId;
    if (window.location.pathname === "/permission/no-access") {
      this.router.navigateByUrl("dashboard");
    } else {
      window.location.href = this.router.url;
    }
    this.onSpaceChanged.next(spaceId);
  }
}
